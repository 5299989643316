<template>
    <section class="almacen-materiales-info px-3">
        <titulo-divisor titulo="Lista de stock de materiales" class="my-4">
            <div class="row">
                <div class="col auto">
                    <search :auto-search="false" :data-to-search="materialHistory.data" placeholder="Buscar" @filtered="filterHistory"/>
                </div>
            </div>
        </titulo-divisor>
        <tabla-general :mostrarBuscador="false" alturaTabla="500px" :data="materialHistory.data" :usarServidor="true" :usarPaginacion="true" :servidorData="materialHistory">
            <el-table-column label="ID " prop="id" width="50" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ scope.row.id }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha de movimiento " prop="created_at" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.created_at | helper-fecha('DD MMM. YYYY') }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Unidades en movimiento " prop="unidades" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.unidades }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Movimiento " prop="tipo" width="150">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 d-flex">
                        <i :class="`f-20 ${scope.row.tipo === 1  ? 'icon-login-variant text-success' : 'icon-logout-variant text-danger'} `" />
                        <span> {{ scope.row.tipo === 1 ? 'Entrada' : 'Salida' }} </span>
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Unidades disponibles " prop="unidades_disponibles" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.unidades_disponibles }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Bodega " prop="bodega" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.bodega }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Solicitante " prop="solicitante" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.solicitante }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Proyecto " prop="proyecto" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.proyecto }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Responsable " width="115" prop="user.nombre" >
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.user.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Comentarios" prop="comentario" width="115" align="left">
                <template slot-scope="scope">
                    <el-tooltip placement="bottom-end" effect="light" visible-arrow>
                        <template #content>
                            <div class="d-flex px-2 pt-2">
                                <img :src=" scope.row.user.imagen | helper-storage " alt="" width="45" height="45" class="rounded-circle">
                                <div class="my-auto ml-2">
                                    <p class="f-12 f-600 mb-1">{{scope.row.user.nombre}}</p>
                                </div>
                            </div>
                            <div class="mt-3 pb-2 px-2">
                                <p class="f-10 lh-13">
									{{scope.row.comentario}}
                                </p>
                            </div>
                        </template>
                        <i :class="`icon-message-reply-text f-20 cr-pointer ${scope.row.cantComentarios > 0  ? 'text-general' : ''} `" />
                    </el-tooltip>
                </template>
            </el-table-column>
        </tabla-general>
    </section>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'

export default {
    data(){
        return{
            buscarListado: '',
        }
    },
    computed:{
        ...mapGetters({
            materialHistory: 'almacen/stock/materialHistory',
        })
    },
    methods:{
        ...mapActions({
            obtenerHistoriaMaterial: 'almacen/stock/Action_get_material_history',
        }),
        ...mapMutations({
            setMaterialHistory: 'almacen/stock/set_material_history'
        }),
        async filterHistory(filtered){
            if(filtered.length){
                const customMaterialHistory = {...this.materialHistory, data: filtered}
                this.setMaterialHistory(customMaterialHistory)
                this.$notify({title: `Busqueda actualizada: ${filtered.length} resultados...`, type: 'info'})
            } else {
                const { id } = this.$route.params
                await this.$store.dispatch('almacen/stock/Action_get_material_history', { id })
                this.$notify({title: 'No se encontraron resultados', type: 'info'})
            }
        }
    },
    async mounted(){
        const id = await this.$route.params
        await this.obtenerHistoriaMaterial( id )
    }

}
</script>

<style lang="scss" scoped>
.almacen-materiales-info{
    .img-material{
        width: 30;
    }
   
}
</style>